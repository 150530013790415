import ReactDOM from "react-dom";
import React, { useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Space,
  Button,
  Divider,
  Card,
  Input,
  message,
  Modal,
  Avatar,
  Form,
  notification,
} from "antd";
import moment from "moment-timezone";
import ss from "seededshuffle";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/Layout";
import Typography from "../styles/Typography";
import {
  checkIsLoggedInWithStims,
  getStimsFromData,
  loginWithStims,
  UU_KEY,
  PP_KEY,
} from "../auth";
import zapier from "../network/zapier";
import analytics from "../utils/analytics";
import colors from "../styles/colors";

import iterativeLogo from "../images/iterative-logo-white-on-black.png";

import placeholder1long from "../images/placeholder_1long.jpg";
import placeholderHalana from "../images/placeholder_halana.jpg";
import placeholderWeassist from "../images/placeholder_weassist.jpg";
import placeholderArshipelago from "../images/placeholder_arshipelago.jpg";
import placeholderLawdify from "../images/placeholder_lawdify.jpg";
import placeholderWaxwing from "../images/placeholder_waxwing.jpg";
import placeholderArmastec from "../images/placeholder_armastec.jpg";
import placeholderPersona from "../images/placeholder_persona.jpg";
import placeholderMomofin from "../images/placeholder_momofin.jpg";
import placeholderFluentjoy from "../images/placeholder_fluentjoy.jpg";
import placeholderBlitz from "../images/placeholder_blitz.jpg";
import placeholderGohub from "../images/placeholder_gohub.jpg";
import placeholderSansdebt from "../images/placeholder_sansdebt.jpg";
import placeholderSuperbench from "../images/placeholder_superbench.jpg";
import placeholderFinskor from "../images/placeholder_finskor.jpg";
import placeholderRootally from "../images/placeholder_rootally.jpg";
import placeholderArogga from "../images/placeholder_arogga.jpg";
import placeholderTaptalent from "../images/placeholder_taptalent.jpg"


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// ~~~~~~~~~~~~~ Configurable variables ~~~~~~~~~~~~~
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const STICKY_BANNER_CONTENT =
  "😻 Company pitch videos will be added immediately after the Demo Day Virtual Event on 8 Sep at 12 PM SGT.";
const STICKY_BANNER_HEIGHT = 50;
const DEMO_DAY_START_TIME_FRIENDLY = "2022-05-10T08:00"; // Use this start time to force Demo day to "Start"
const SINGAPORE_TIMEZONE_UTC_OFFSET = "+08:00"; // + hh:mm
const INTRODUCTION_VIDEO_VIMEO_LINK =
  "https://player.vimeo.com/video/943550105";
const COMPANIES = [
  {
    "name": "1Long",
    "oneLiner": "Wealth Management for Vietnam's Rising Affluent",
    "description": "1Long is a digital wealth management platform targeting the rising affluent in Vietnam. We offer innovative saving products and financial planning resources that help our customers meet their investment, legal, and aspirational objectives. Since our launch 4 months ago, we have surpassed $500K in AUM and 300 funded accounts, growing at 80% MoM. By catching this wave of passive investment, we can onboard billions of dollars into responsible, long-term capital management solutions.",
    "pitchVideoUrl": "https://player.vimeo.com/video/943129309",
    "useStaticImage": false,
    "staticImageUrl": placeholder1long,
    "qnaVideoUrl": "https://player.vimeo.com/video/943129309",
    "members": [
      {
        "firstName": "Michael",
        "lastName": "Do",
        "position": "CEO",
        "linkedInUrl": "https://www.linkedin.com/in/michaeldo710/",
        "headshotUrl": "https://media.licdn.com/dms/image/C4D03AQE-O5FiwGEeYA/profile-displayphoto-shrink_800_800/0/1647094998148?e=1720656000&v=beta&t=wSjsLV4J_9tJKSkHsqYOO3VKCNMgdF23VboKsNCOoTM"
      },
      {
        "firstName": "Joshua",
        "lastName": "Hong",
        "position": "CBO",
        "linkedInUrl": "https://www.linkedin.com/in/joshua-hong-72ba916a/",
        "headshotUrl": "https://media.licdn.com/dms/image/D5603AQE4eZhErEPKCw/profile-displayphoto-shrink_800_800/0/1713323508167?e=1720656000&v=beta&t=CHqekFSnmYYYHVPyYJqKUOMsC4Dk6xIX26wITRk_KMM"
      },
      {
        "firstName": "Hoang",
        "lastName": "Nguyen",
        "position": "COO",
        "linkedInUrl": "https://www.linkedin.com/in/hoang-nguyen-528480b2/",
        "headshotUrl": "https://media.licdn.com/dms/image/D5603AQHmLbhpUYmoNg/profile-displayphoto-shrink_800_800/0/1715002452651?e=1720656000&v=beta&t=ogAq3pnP0i8dtVzuut0zlX2R-EY_fKm3NjcUTCKKze8"
      }
    ],
  },
  {
    "name": "Arshipelago",
    "oneLiner": "“Booking.com” For Domestic Ocean Shipments in Indonesia",
    "description": "Arshipelago tackles the inefficiencies in Indonesia's domestic ocean shipment industry, where only 1 out of 10 of inquiries from cargo owners to freight forwarders are answered promptly. Our solution is designed for freight forwarders who need quick access to shipping rates and schedules. We simplify the process by offering instant rate and schedule checking on our platform, slashing processing time from days to minutes. Since our product launch, Arshipelago has seen an impressive 350% month-over-month growth, signaling strong demand for a simpler shipping solution and potentially over $200,000 in annual revenue.",
    "pitchVideoUrl": "https://player.vimeo.com/video/943131182",
    "useStaticImage": false,
    "staticImageUrl": placeholderArshipelago,
    "qnaVideoUrl": "https://player.vimeo.com/video/943131182",
    "members": [
      {
        "firstName": "Ade",
        "lastName": "Fahrizal",
        "position": "CEO",
        "linkedInUrl": "https://www.linkedin.com/in/ade-fahrizal/?originalSubdomain=id",
        "headshotUrl": "https://media.licdn.com/dms/image/D5603AQG_2KtWq9d4Nw/profile-displayphoto-shrink_800_800/0/1711248264520?e=1720656000&v=beta&t=-u_18Q4ZFjhSkB9mvcjBy13Xwpmb2xGMqsVPkuPqHhg"
      },
      {
        "firstName": "Achmad",
        "lastName": "Feisal Anshari",
        "position": "CCO",
        "linkedInUrl": "https://www.linkedin.com/in/achmadfeisal/",
        "headshotUrl": "https://media.licdn.com/dms/image/C5103AQHhxxzfpdvCnQ/profile-displayphoto-shrink_800_800/0/1573607810453?e=1720656000&v=beta&t=DNPSuoYvr96l0GvP5V0GbmX8CeUJxe7D8IEcEat0mb4"
      },
      {
        "firstName": "Andriana",
        "lastName": "Polisenawati",
        "position": "CPO",
        "linkedInUrl": "https://www.linkedin.com/in/andrianapsw/",
        "headshotUrl": "https://media.licdn.com/dms/image/C4D03AQE3EiwmmkGdsg/profile-displayphoto-shrink_800_800/0/1517248744107?e=1720656000&v=beta&t=5T4nmV99NE9GSHSs9hICSbu5rR6IUC_r1UABtdv7_g8"
      }
    ]
  },
  {
    "name": "Rootally",
    "oneLiner": "AI Therapist for Musculoskeletal Conditions",
    "description": "Rootally AI addresses the rise in musculoskeletal issues and the global shortage of physiotherapists by providing patients and therapists with an AI-powered solution. Our advanced motion-tracking technology enables accurate assessment, treatment, and progress monitoring, reducing face-to-face consultations by up to 50% while delivering improved clinical outcomes.",
    "pitchVideoUrl": "https://player.vimeo.com/video/943132245",
    "useStaticImage": false,
    "staticImageUrl": placeholderRootally,
    "qnaVideoUrl": "https://player.vimeo.com/video/943132245",
    "members": [
      {
        "firstName": "Amit",
        "lastName": "Jindal",
        "position": "CEO",
        "linkedInUrl": "https://www.linkedin.com/in/amitjindal7/",
        "headshotUrl": "https://media.licdn.com/dms/image/D5603AQG2KyJWKv4Uow/profile-displayphoto-shrink_800_800/0/1685784368269?e=1720656000&v=beta&t=vDsZLocllCfORnyPwM-nR6PWxUvsAJjRnwAyYccdXeI"
      },
      {
        "firstName": "Danish",
        "lastName": "Ali",
        "position": "COO",
        "linkedInUrl": "https://www.linkedin.com/in/danish-ali-5bb936183/?originalSubdomain=in",
        "headshotUrl": "https://media.licdn.com/dms/image/D5635AQGqrwRqFg8MaQ/profile-framedphoto-shrink_800_800/0/1711276019636?e=1715662800&v=beta&t=-jwyZMtn7OtzOD26qSHs6B3QqckTiy8wZzSjWG-YRjw"
      }
    ]
  },
  {
    "name": "ArmasTec",
    "oneLiner": "Empowering the Human Body through Fabric Exoskeletons",
    "description": "The ArmasTec AireLevate is a fabric-based exoskeleton designed to enhance productivity and significantly reduce injury cases in manual work settings, such as a warehouse, hospital or factory. 65% of the workforce still relies on physical labour. We were promised a robotic future but adoption rates are already slowing down. Embracing a pioneering fabric design, the AireLevate mitigates back strain by half, is more cost-effective (less than a worker's salary), and boasts such comfort when worn that its presence can be easily forgotten. Our exoskeleton orders are growing 30% monthly, all through inbound sales.",
    "pitchVideoUrl": "https://player.vimeo.com/video/943131131",
    "useStaticImage": false,
    "staticImageUrl": placeholderArmastec,
    "qnaVideoUrl": "https://player.vimeo.com/video/943131131",
    "members": [
      {
        "firstName": "Rainier",
        "lastName": "Natividad",
        "position": "CEO",
        "linkedInUrl": "https://www.linkedin.com/in/rainierfn/",
        "headshotUrl": "https://media.licdn.com/dms/image/C5603AQGbpsuiaUq7mQ/profile-displayphoto-shrink_800_800/0/1599293320385?e=1720656000&v=beta&t=W-vulAUtAvCxm_9nckOpaZuzFDKN0CwkgjmwlvNAAtY"
      }
    ]
  },
  {
    "name": "Arogga",
    "oneLiner": "Bangladesh's Largest Online Pharmacy",
    "description": "Arogga is a health-tech start-up tackling problems around access to medicine in a market where 20-30% medicines in circulation are counterfeit. Arogga provides 100% genuine medicine, directly from the manufacturers, conveniently to your door at an affordable price. Arogga is building a healthcare platform which includes lab testing home sample collection services, and verticals of doctor consultations and health insurance to be launched later this year.",
    "pitchVideoUrl": "https://player.vimeo.com/video/943131154",
    "useStaticImage": false,
    "staticImageUrl": placeholderArogga,
    "qnaVideoUrl": "https://player.vimeo.com/video/943131154",
    "members": [
      {
        "firstName": "Rosina",
        "lastName": "Mazumder",
        "position": "CEO",
        "linkedInUrl": "https://www.linkedin.com/in/rosina-mazumder-66218a45/",
        "headshotUrl": "https://media.licdn.com/dms/image/D4E03AQFYZOJIOwNF2g/profile-displayphoto-shrink_800_800/0/1707991030844?e=1720656000&v=beta&t=Ahcxb_KownYIoz46Azgxm9ve9PgoIRlyTrG20juSa7M"
      },
      {
        "firstName": "Fahad",
        "lastName": "Hossain",
        "position": "COO",
        "linkedInUrl": "https://www.linkedin.com/in/fahadsden/",
        "headshotUrl": "https://media.licdn.com/dms/image/C5603AQGgoemGF-NP3Q/profile-displayphoto-shrink_800_800/0/1648821147753?e=1720656000&v=beta&t=RMjNagjxRah4S3VgM6nwRkF2gEhi2HndYTRtvGKcMUs"
      },
      {
        "firstName": "Shamim",
        "lastName": "Hasan",
        "position": "CTO",
        "linkedInUrl": "https://www.linkedin.com/in/shamim-hasan-bb6a40204/",
        "headshotUrl": "https://media.licdn.com/dms/image/D5635AQGkuSRwDlCWgw/profile-framedphoto-shrink_800_800/0/1713950880745?e=1715662800&v=beta&t=8Lz2PyMPx1opzxt3cTHLU5unEerpuUWcqB9ijX8qnMQ"
      },
      {
        "firstName": "Yawar",
        "lastName": "Mehboob",
        "position": "CFO",
        "linkedInUrl": "https://www.linkedin.com/in/yawar-mehboob-0490bb31/",
        "headshotUrl": "https://media.licdn.com/dms/image/C5603AQHjNsTP2TbQCg/profile-displayphoto-shrink_800_800/0/1649330024533?e=1720656000&v=beta&t=dwTilnYghwDVGJEJTCemS_399K12x8iIepAfHy8WhLE"
      }
    ]
  },
  {
    "name": "Blitz",
    "oneLiner": "Amazon Prime Instant Deliveries for Southeast Asia",
    "description": "We are a data-driven EV logistic start up in Indonesia backed by ADB Ventures, Iterative, FiveFortyAlpha, BonBillo, and Third Derivative. Enterprises and SMEs white label our logistics and EV telematics platform to perform last mile deliveries, resulting in 99%+ SLA guarantees with up to 16% shorter delivery times and 33% more deliveries. Unlike traditional 3PL providers, our offerings enable white labelling of last mile logistic services that give clients complete control of deliveries at an affordable price. We’ve grown our FY2023 Net Revenue 37x in the past 12 months to USD 1.5 Million performing 100K monthly deliveries and have been operating for 23 months.",
    "pitchVideoUrl": "https://player.vimeo.com/video/943132128",
    "useStaticImage": false,
    "staticImageUrl": placeholderBlitz,
    "qnaVideoUrl": "https://player.vimeo.com/video/943132128",
    "members": [
      {
        "firstName": "Saivya",
        "lastName": "Chauhan",
        "position": "CEO",
        "linkedInUrl": "https://www.linkedin.com/in/saivyachauhan/?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=id",
        "headshotUrl": "https://media.licdn.com/dms/image/C5603AQH5XdMU5g_U-w/profile-displayphoto-shrink_800_800/0/1612442118891?e=1720656000&v=beta&t=qo96jD_ptRfr09ZGdIx_MtLvcw7YCeqC3jd_VQABBrc"
      }
    ]
  },
  {
    "name": "Finskor",
    "oneLiner": "Reducing Fraud with Smart Bank Statement Analysis",
    "description": "Finskor is a SaaS company that empowers local banks to excel in managing their borrower data by ingesting all the borrower documents, checking for frauds and helping them to hack their NPL.",
    "pitchVideoUrl": "https://player.vimeo.com/video/943137020",
    "useStaticImage": false,
    "staticImageUrl": placeholderFinskor,
    "qnaVideoUrl": "https://player.vimeo.com/video/943137020",
    "members": [
      {
        "firstName": "Wildiyanto",
        "lastName": "Yawin",
        "position": "CEO",
        "linkedInUrl": "https://www.linkedin.com/in/wyawin/?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=id",
        "headshotUrl": "https://media.licdn.com/dms/image/D5603AQFcVEnqY53-eg/profile-displayphoto-shrink_800_800/0/1712325906472?e=1720656000&v=beta&t=-HuREL_LZ6h8XCAoYwfuCGr8e_YhRyL5XCbBz_oy6aY"
      },
      {
        "firstName": "Yohanes",
        "lastName": "Rico",
        "position": "COO",
        "linkedInUrl": "https://www.linkedin.com/in/yohanes-rico/?originalSubdomain=id",
        "headshotUrl": "https://media.licdn.com/dms/image/D5603AQGLlniNYwg7NA/profile-displayphoto-shrink_800_800/0/1687913445727?e=1720656000&v=beta&t=86RafWamlTjdAXIpvb4dzJXMRjqKoUgXvQYL8i2_nKU"
      },
      {
        "firstName": "Yuliam",
        "lastName": "Chandra",
        "position": "CTO",
        "linkedInUrl": "https://www.linkedin.com/in/yuliam-chandra/?originalSubdomain=id",
        "headshotUrl": ""
      }
    ]
  },
  {
    "name": "Fluentjoy",
    "oneLiner": "The #1 Short-Video App for English Learners",
    "description": "Fluentjoy revolutionizes English learning by combining the learning science of English app with the virality and engagement of short-video platforms. Our platform is packed with 3,000+ video lessons, quizzes and conversation practices offering our users an interactive, dynamic, and engaging learning experience. This unique approach delivers not only a better learning outcome but also a 50% higher retention than the education apps benchmark.",
    "pitchVideoUrl": "https://player.vimeo.com/video/943132148",
    "useStaticImage": false,
    "staticImageUrl": placeholderFluentjoy,
    "qnaVideoUrl": "https://player.vimeo.com/video/943132148",
    "members": [
      {
        "firstName": "Jose",
        "lastName": "Lis",
        "position": "CEO",
        "linkedInUrl": "https://www.linkedin.com/in/joselis/",
        "headshotUrl": "https://media.licdn.com/dms/image/D5635AQGibPT2K1p7Mg/profile-framedphoto-shrink_800_800/0/1711342357248?e=1715662800&v=beta&t=w01NUKbA-5AsDJIkWNyHvR_jWroQ6IwJTzOqM4bpNVA"
      }
    ]
  },
  {
    "name": "Gohub",
    "oneLiner": "The Travel eSIM App For Everyone",
    "description": "We are on a mission to help travelers in APAC have a better internet experience with the new eSIM movement. Forget about expensive roaming fees & clumsy physical SIM cards, we are making the eSIM experience as seamless as possible with affordable, reliable and high-speed local eSIM packages.",
    "pitchVideoUrl": "https://player.vimeo.com/video/943132167",
    "useStaticImage": false,
    "staticImageUrl": placeholderGohub,
    "qnaVideoUrl": "https://player.vimeo.com/video/943132167",
    "members": [
      {
        "firstName": "Trường (Buck)",
        "lastName": "Phan",
        "position": "CEO",
        "linkedInUrl": "https://www.linkedin.com/in/buckphan/",
        "headshotUrl": "https://media.licdn.com/dms/image/D5635AQFkYfFrxJeebQ/profile-framedphoto-shrink_800_800/0/1710408029917?e=1715662800&v=beta&t=etV6VQLG11yP53sYcbGcV5XN2zWOv4uS4BOAfqnWYYo"
      },
      {
        "firstName": "Seiko",
        "lastName": "Bao",
        "position": "CGO",
        "linkedInUrl": "https://www.linkedin.com/in/lethaibao/",
        "headshotUrl": "https://media.licdn.com/dms/image/D5635AQHQxjBHIOAsrw/profile-framedphoto-shrink_800_800/0/1711205047026?e=1715662800&v=beta&t=WHgcq0pIvTJypJ125_PLYbexKkSbqcHgHRRWoLSN-fs"
      }
    ]
  },
  {
    "name": "Halana",
    "oneLiner": "The Leading B2B Marketplace Consolidating Vietnam's Industrial Goods Retail Market",
    "description": "Halana digitally transforms the industrial goods retail market by eliminating middlemen and granting direct access to upper supply chain sources for Vietnam's industrial mom & pop stores. With a 10% boost in margins, an extensive e-catalog of 50,000 pre-vetted SKUs, and reliable doorstep delivery within 2 days, coupled with top-notch after-sales support, we redefine convenience and efficiency in industrial goods procurement.",
    "pitchVideoUrl": "https://player.vimeo.com/video/943132181",
    "useStaticImage": false,
    "staticImageUrl": placeholderHalana,
    "qnaVideoUrl": "https://player.vimeo.com/video/943132181",
    "members": [
      {
        "firstName": "Anthony",
        "lastName": "Ho",
        "position": "CEO",
        "linkedInUrl": "https://www.linkedin.com/in/ho-phi-an-halana/",
        "headshotUrl": "https://media.licdn.com/dms/image/D4E35AQFA0FLcA3_4NQ/profile-framedphoto-shrink_800_800/0/1710921194096?e=1715659200&v=beta&t=PycIEeYa0sTDXv3Fut2d76Xg9Z9Tn4UEzqcaC9xtZH4"
      },
      {
        "firstName": "Jacky",
        "lastName": "Nguyễn",
        "position": "CTO",
        "linkedInUrl": "https://www.linkedin.com/in/jackynguyen110/",
        "headshotUrl": "https://media.licdn.com/dms/image/C4D03AQGpB6c5qGtxCQ/profile-displayphoto-shrink_800_800/0/1622821731812?e=1720656000&v=beta&t=6CX1X8kuC8sN9mHSUTUqlOXZVpXnT8dg4v94s19qFbA"
      }
    ]
  },
  {
    "name": "Lawdify",
    "oneLiner": "Automating Fact-finding for the $500B Disputes Industry",
    "description": "At Lawdify, we are transforming the USD500 billion disputes industry by automating the most painful yet foundational part of disputes work, fact-finding. Designed specifically for the intricate process of fact-finding, our platform excels in mapping out the unique DNA of each case—encompassing relationships, people, events, and dates. Lawdify delivers results up to 600 times faster than traditional manual document review for fact-finding. More than just speed, our system uncovers pivotal facts that forge stronger, more compelling cases. With Lawdify, legal professionals can focus on strategy and advocacy, secure in the knowledge that they have the most relevant and powerful facts at their disposal.",
    "pitchVideoUrl": "https://player.vimeo.com/video/943132198",
    "useStaticImage": false,
    "staticImageUrl": placeholderLawdify,
    "qnaVideoUrl": "https://player.vimeo.com/video/943132198",
    "members": [
      {
        "firstName": "Eliza",
        "lastName": "Jiang",
        "position": "CEO",
        "linkedInUrl": "https://www.linkedin.com/in/elizajiang/",
        "headshotUrl": "https://media.licdn.com/dms/image/C4D03AQFm1w6XxqXNDw/profile-displayphoto-shrink_800_800/0/1655106803306?e=1720656000&v=beta&t=GDDS2BBPT_vd1Qvfho-F4ZS-EFKvDCKQZ_UlgaFr1EU"
      },
      {
        "firstName": "David",
        "lastName": "Lucker",
        "position": "CTO",
        "linkedInUrl": "https://www.linkedin.com/in/david-lucker-a4727374/",
        "headshotUrl": "https://media.licdn.com/dms/image/D5635AQHiqmkW1xfxlg/profile-framedphoto-shrink_800_800/0/1714489840747?e=1715662800&v=beta&t=wDSJTnF6PwYzjIJ4VvKwrrEEbSD-4EdAqSd95INt3xQ"
      }
    ]
  },
  {
    "name": "Momofin",
    "oneLiner": "Building Trust in Digital Document Process",
    "description": "Momofin is revolutionizing trust in digital document processing, starting with essential services like e-signatures, document management systems, and e-meterai (digital stamp duty). Now, Momofin is pioneering forward with cutting-edge solutions for document and certificate authentication, ensuring unparalleled security and authenticity. This progression not only enhances operational efficiencies but also fortifies the integrity of digital transactions. Momofin is the future of secure digital documentation, where every document and contract signed and stamped is a step towards a more trustworthy digital world",
    "pitchVideoUrl": "https://player.vimeo.com/video/943132215",
    "useStaticImage": false,
    "staticImageUrl": placeholderMomofin,
    "qnaVideoUrl": "https://player.vimeo.com/video/943132215",
    "members": [
      {
        "firstName": "Rorian",
        "lastName": "Pratyaksa",
        "position": "CEO",
        "linkedInUrl": "https://www.linkedin.com/in/rorianp/",
        "headshotUrl": "https://media.licdn.com/dms/image/D5603AQHOCNbcv7H3hQ/profile-displayphoto-shrink_800_800/0/1685117130201?e=1720656000&v=beta&t=5DX376uiPDp4f6QO1MOWafIXr2OD1eLlOV7chzx1C8s"
      },
      {
        "firstName": "Darell",
        "lastName": "Hoei",
        "position": "CTO",
        "linkedInUrl": "https://www.linkedin.com/in/darell-hoei/",
        "headshotUrl": "https://media.licdn.com/dms/image/C5603AQF8XcE8sXgrtA/profile-displayphoto-shrink_800_800/0/1547069641063?e=1720656000&v=beta&t=iB-NeEhyz_twHTv9vYce2aO-8i63TVvb17Wa_cQEvG0"
      },
      {
        "firstName": "Gregorius",
        "lastName": "Yoga P.",
        "position": "CFO",
        "linkedInUrl": "https://www.linkedin.com/in/gyp/",
        "headshotUrl": "https://media.licdn.com/dms/image/C5603AQF1yeNRVyJfUQ/profile-displayphoto-shrink_800_800/0/1638756551698?e=1720656000&v=beta&t=11GFhU1zyfC2DEnGvAQdpq8VRk7elFnLWgT7kEDBnPE"
      }
    ]
  },
  {
    "name": "Persona Studios",
    "oneLiner": "Canva for Conversational AI",
    "description": "Build omnichannel, multilingual, conversational AI personas for your business that automate calls ranging from new customer onboarding, customer support, inside sales, and more. If it’s a 20-60 minute call an employee does day-in and day-out for any reason, our app can automate it regardless of use case or vertical, and tie the results to your existing CRM.",
    "pitchVideoUrl": "https://player.vimeo.com/video/943132233",
    "useStaticImage": false,
    "staticImageUrl": placeholderPersona,
    "qnaVideoUrl": "https://player.vimeo.com/video/943132233",
    "members": [
      {
        "firstName": "Kevin",
        "lastName": "Davis",
        "position": "CEO",
        "linkedInUrl": "https://www.linkedin.com/in/kevin-davis-a2162531/",
        "headshotUrl": "https://media.licdn.com/dms/image/D5603AQECtY-fpYsAmA/profile-displayphoto-shrink_800_800/0/1685962215367?e=1720656000&v=beta&t=oxbmfH-6MJB1sLOAMtGha7hkc3-7AnEhcfakD2qHVT4"
      },
      {
        "firstName": "Andrew",
        "lastName": "McCrow",
        "position": "COO",
        "linkedInUrl": "https://www.linkedin.com/in/andrewmccrow/?originalSubdomain=sg",
        "headshotUrl": "https://media.licdn.com/dms/image/C5603AQEklVlsSMg8Rg/profile-displayphoto-shrink_800_800/0/1634590944327?e=1720656000&v=beta&t=xCLprm03iJielSRCkhyoViXUTCiP8jUiEHXbtKP1mOQ"
      }
    ]
  },
  {
    "name": "SansDebt",
    "oneLiner": "Turning Overdue Invoices into Revenue",
    "description": "SansDebt enhances your account receivable team from day one of overdue. We use mediation, negotiation and psychology fined tuned AI to recover the money overdue. Our Small Claim Officer is infinitely patient, with restructuring capability and have a multi-channel approach including phone calls for the best results and at lower cost than your in-house or external AR team.",
    "pitchVideoUrl": "https://player.vimeo.com/video/943132269",
    "useStaticImage": false,
    "staticImageUrl": placeholderSansdebt,
    "qnaVideoUrl": "https://player.vimeo.com/video/943132269",
    "members": [
      {
        "firstName": "Arnaud",
        "lastName": "Rubeck",
        "position": "CEO",
        "linkedInUrl": "https://www.linkedin.com/in/arnaudrubeck/?originalSubdomain=sg",
        "headshotUrl": "https://media.licdn.com/dms/image/D5603AQE0RE7n7B6mhA/profile-displayphoto-shrink_800_800/0/1681601712313?e=1720656000&v=beta&t=sjx_FESEUxTop6peXDsPXfLn6Prn_u1onu7TYmpsiys"
      },
      {
        "firstName": "Andrei",
        "lastName": "Epure",
        "position": "CTO",
        "linkedInUrl": "",
        "headshotUrl": ""
      }
    ]
  },
  {
    "name": "Superbench",
    "oneLiner": "AI Operating System For Home Services Companies",
    "description": "Superbench is the AI powered ServiceNow for service companies in Asia. We enable these companies to deliver the best online customer experience with minimum effort, so they can fully focus on offline service delivery.",
    "pitchVideoUrl": "https://player.vimeo.com/video/943132296",
    "useStaticImage": false,
    "staticImageUrl": placeholderSuperbench,
    "qnaVideoUrl": "https://player.vimeo.com/video/943132296",
    "members": [
      {
        "firstName": "Jingjing",
        "lastName": "Zhong",
        "position": "CEO",
        "linkedInUrl": "https://www.linkedin.com/in/jingjing-zhong/",
        "headshotUrl": "https://media.licdn.com/dms/image/D5603AQFXHtK8NYDeqQ/profile-displayphoto-shrink_800_800/0/1709396420318?e=1720656000&v=beta&t=3MguGlXBty3WV7wEGmUC1boT5K65hrV8JxxbBGxQVT0"
      },
      {
        "firstName": "Dmitrii",
        "lastName": "Bolotov",
        "position": "CTO",
        "linkedInUrl": "https://www.linkedin.com/in/dmitrii-bolotov/",
        "headshotUrl": "https://media.licdn.com/dms/image/C4D03AQGo9mEbHuQEoQ/profile-displayphoto-shrink_800_800/0/1651159341998?e=1720656000&v=beta&t=gT4R-uLjSv1M9j8SabN72MosBPLsrqtPZcjTLvZd6Lg"
      },
      {
        "firstName": "Yan Kai",
        "lastName": "Ng",
        "position": "CPO",
        "linkedInUrl": "https://www.linkedin.com/in/yankaing/",
        "headshotUrl": "https://media.licdn.com/dms/image/D5603AQH5aTuYasj3Ww/profile-displayphoto-shrink_800_800/0/1681129876204?e=1720656000&v=beta&t=Cagx18xj87hvXRgjQJlTVie5x_vkMbT4mmxphVDSxWc"
      }
    ]
  },
  {
    "name": "TapTalent",
    "oneLiner": "Automated Outbound Recruiting Engine to Help Recruiters Speak to the Best Candidates Today",
    "description": "TapTalent helps recruiters start conversations with qualified candidates instantly using it's database of 800 million candidates and automated and personalised reachouts, follow ups on whatsapp, email, linkedin, calls, etc.",
    "pitchVideoUrl": "https://player.vimeo.com/video/943132307",
    "useStaticImage": false,
    "staticImageUrl": placeholderTaptalent,
    "qnaVideoUrl": "https://player.vimeo.com/video/943132307",
    "members": [
      {
        "firstName": "Dhruv",
        "lastName": "Bhagat",
        "position": "CEO",
        "linkedInUrl": "https://www.linkedin.com/in/dhruvbhagat88/",
        "headshotUrl": "https://media.licdn.com/dms/image/D5603AQE0q15hueB7zw/profile-displayphoto-shrink_800_800/0/1704199424447?e=1720656000&v=beta&t=3lM9ux8NTq159H0MN3xtikN90W60LGNR8fp1dJLnhbo"
      },
      {
        "firstName": "Shubham",
        "lastName": "Kumar Arora",
        "position": "CTO",
        "linkedInUrl": "https://www.linkedin.com/in/shubham-kumar-arora/",
        "headshotUrl": "https://media.licdn.com/dms/image/C5103AQHpkCaHKRYVLw/profile-displayphoto-shrink_800_800/0/1569392279659?e=1720656000&v=beta&t=_ppO7gPYB3kDQau6bibaJ39jvK-tMJqPNjtzlG-mMwY"
      }
    ]
  },
  {
    "name": "Waxwing",
    "oneLiner": "AI Copilot for Growth Marketers",
    "description": "The only AI tool you will ever need to ideate, plan, and execute all your growth marketing projects. From proven marketing strategies personalized for your target audience to a context-aware copilot, we are weaving it all together in a collaborative interface. We have been growing 50% week-on-week since our launch and voted as the best product of the day on ProductHunt.",
    "pitchVideoUrl": "https://player.vimeo.com/video/943132322",
    "useStaticImage": false,
    "staticImageUrl": placeholderWaxwing,
    "qnaVideoUrl": "https://player.vimeo.com/video/943132322",
    "members": [
      {
        "firstName": "Tarun",
        "lastName": "Jain",
        "position": "CEO",
        "linkedInUrl": "https://www.linkedin.com/in/creativenemo/?originalSubdomain=th",
        "headshotUrl": "https://media.licdn.com/dms/image/D5603AQEvePg5OkY_JA/profile-displayphoto-shrink_800_800/0/1709540002195?e=1720656000&v=beta&t=yfzTWDT6DZRh9qVBuL-y2GtTQ9rNMH0BJgpYlOuRFpA"
      },
      {
        "firstName": "Samarth",
        "lastName": "Thripathi",
        "position": "CTO",
        "linkedInUrl": "https://www.linkedin.com/in/samarth-waxwing/",
        "headshotUrl": "https://media.licdn.com/dms/image/C5603AQEt3k0gTtVRaw/profile-displayphoto-shrink_800_800/0/1602470121418?e=1720656000&v=beta&t=ItDbXgy9vy7Ts3lwXVsZj5DGsBwo28STc5UWCUFnszI"
      }
    ]
  },
  {
    "name": "WeAssist",
    "oneLiner": "The Healthcare Staffing Marketplace with the Better Way to Fill All Healthcare Vacancies",
    "description": "Locum Apps is a marketplace that matches healthcare staff to vacancies in licensed hospitals and clinics. Healthcare staffing has been in crisis for the past 30 years, with up to half of all hospital shifts being filled by locum staff. However, the process of finding and hiring locum staff can be complex and time-consuming. That's where Locum Apps comes in. Our platform simplifies the recruitment process, saving hospitals time and money while providing additional income for staff. Join us in eliminating the healthcare staffing crisis together!",
    "pitchVideoUrl": "https://player.vimeo.com/video/943132341",
    "useStaticImage": false,
    "staticImageUrl": placeholderWeassist,
    "qnaVideoUrl": "https://player.vimeo.com/video/943132341",
    "members": [
      {
        "firstName": "Tiffany",
        "lastName": "Khoo",
        "position": "CEO",
        "linkedInUrl": "https://www.linkedin.com/in/tiffany-khoo/",
        "headshotUrl": "https://media.licdn.com/dms/image/D5603AQHSNn8OED1m4g/profile-displayphoto-shrink_800_800/0/1709278611946?e=1720656000&v=beta&t=toPsCiRB61OueLAS8AZqSMBI7zanJLAb4DKqFxlIaJ0"
      }
    ]
  }

];

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// ~~~~~~~~~~~~~ Configurable variables ~~~~~~~~~~~~~
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

const DEMO_DAY_START_TIME = moment(
  `${DEMO_DAY_START_TIME_FRIENDLY}${SINGAPORE_TIMEZONE_UTC_OFFSET}`
);

const CompanyCard = ({
  name,
  oneLiner,
  description,
  pitchVideoUrl,
  staticImageUrl,
  qnaVideoUrl,
  members,
  useStaticImage = false,
}) => {
  const [connectLoading, setConnectLoading] = useState(false);
  const [showLoveLoading, setShowLoveLoading] = useState(false);
  const [openQnAModal, setOpenQnAModal] = useState(false);
  return (
    <>
      <Row gutter={[30, 30]}>
        <Col xs={24} xl={24}>
          <Space direction="vertical" size={12} style={{ width: "100%" }}>
            {useStaticImage ? (
              <img src={staticImageUrl} style={{ objectFit: "cover" }} />
            ) : (
              /* Embedded Video */
              <div style={{ position: "relative", paddingBottom: "56.25%" }}>
                <iframe
                  allowFullScreen
                  src={`${pitchVideoUrl}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                  width="100%"
                  height="100%"
                  style={{ borderRadius: 4, position: "absolute" }}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  title={`pitch-${name}`}
                />
              </div>
            )}
          </Space>
        </Col>
        <Col xs={24} xl={24}>
          <Space direction="vertical" size={12} style={{ width: "100%" }}>
            <Typography.H3 style={{ color: colors.gray1 }}>
              {name}
            </Typography.H3>
            <Typography.Body1>{oneLiner}</Typography.Body1>
            <Typography.Body2
              style={{ color: colors.secondary, marginBottom: 12 }}
            >
              {description}
            </Typography.Body2>

            <Space direction="vertical" size={12} style={{ marginBottom: 12 }}>
              {members.map(
                ({
                  firstName,
                  lastName,
                  position,
                  linkedInUrl,
                  headshotUrl,
                }) => {
                  const fullName = `${firstName} ${lastName}`;
                  return (
                    <div key={fullName}>
                      <a
                        href={linkedInUrl}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          analytics.track("Home - Clicked Founder Link", {
                            linkedInUrl,
                            fullName,
                          });
                        }}
                        style={{
                          textDecoration: "none",
                          fontSize: 16,
                          color: colors.primary,
                        }}
                      >
                        <Avatar
                          src={headshotUrl}
                          size={50}
                          shape="circle"
                          style={{ marginRight: 12 }}
                        />
                        {fullName}
                      </a>
                      , {position}
                    </div>
                  );
                }
              )}
            </Space>

            <Row wrap gutter={[12, 12]} style={{ paddingBottom: 50 }}>
              <Col span="auto">
                <Button
                  size="large"
                  type="primary"
                  loading={connectLoading}
                  onClick={async () => {
                    analytics.track("Home - Clicked Connect", { name });
                    setConnectLoading(true);
                    await zapier.connectAboutInvesting(name);
                    setConnectLoading(false);

                    notification.open({
                      message: "🤝 Connection Made",
                      description: (
                        <Typography.Body2>
                          An email has been sent to {name} indicating your
                          interest to connect about investing. They will reach
                          out to you shortly.
                        </Typography.Body2>
                      ),
                      duration: 0,
                    });
                  }}
                >
                  Connect About Investing
                </Button>
              </Col>
              <Col span="auto">
                <Button
                  size="large"
                  type="secondary"
                  loading={showLoveLoading}
                  onClick={async () => {
                    analytics.track("Home - Clicked Show Love", { name });
                    setShowLoveLoading(true);
                    await zapier.showLove(name);
                    setShowLoveLoading(false);

                    notification.open({
                      message: "❤️ Love Shown",
                      description: (
                        <Typography.Body2>
                          An email has been sent to {name} showing them your
                          love.
                        </Typography.Body2>
                      ),
                      duration: 0,
                    });
                  }}
                >
                  Show Love
                </Button>
              </Col>
              {/* TODO: For AFTER Demo day */}
              {/* <Col span={24}>
                <Button
                  size="large"
                  type="link"
                  style={{ padding: 0 }}
                  onClick={() => {
                    analytics.track("Home - Clicked Watch Q&A Session");
                    setOpenQnAModal(true);
                  }}
                >
                  Watch Q&A Session
                </Button>
              </Col> */}
            </Row>
          </Space>
        </Col>
      </Row>

      {openQnAModal && (
        <Modal
          centered
          closable={false}
          visible={openQnAModal}
          onCancel={() => {
            analytics.track("Home - Closed Q&A Session Modal");
            setOpenQnAModal(false);
          }}
          footer={null}
          bodyStyle={{
            height: 500,
            padding: 0,
            position: "relative",
            paddingBottom: "56.25%",
            backgroundColor: "transparent",
          }}
          width={1010}
        >
          <iframe
            allowFullScreen
            title={`qna-${name}`}
            src={`${qnaVideoUrl}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
            width="100%"
            height="100%"
            style={{ position: "absolute" }}
            frameBorder="0"
            allow="autoplay; fullscreen"
          />
        </Modal>
      )}
    </>
  );
};

const LoginPage = ({ login, onLoginSuccess }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    analytics.page("Login");
  }, []);

  const onLogin = async ({ email, password }) => {
    analytics.track("Login - Clicked Login");
    setLoading(true);

    // Stimulate a delayed login as if we are making a network request
    setTimeout(() => {
      const loginSuccessful = login(email, password);
      if (loginSuccessful) {
        analytics.track("Login - Login Success", { email });
        analytics.identify(email);
        onLoginSuccess();
        setLoading(false);
      } else {
        analytics.track("Login - Login Failed", { email, password });
        setLoading(false);
        message.error("Login failed. Please try again");
      }
    }, 1010);
  };

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <Row style={{ height: "100%" }}>
        <Col
          xs={24}
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 40,
          }}
        >
          <Card
            style={{
              maxWidth: 450,
              width: "100%",
              boxShadow:
                "0 15px 35px 0 rgb(60 66 87 / 8%), 0 5px 15px 0 rgb(0 0 0 / 12%)",
            }}
          >
            <Form layout="vertical" hideRequiredMark onFinish={onLogin}>
              <Form.Item name="email" label="Email" required>
                <Input size="large" placeholder="jon@gmail.com" />
              </Form.Item>

              <Form.Item name="password" label="Password" required>
                <Input.Password size="large" />
              </Form.Item>

              <Form.Item>
                <Button
                  block
                  htmlType="submit"
                  loading={loading}
                  size="large"
                  type="primary"
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col
          xs={0}
          md={12}
          style={{
            backgroundColor: "black",
            display: "flex",
            justifyContent: "space",
            alignItems: "center",
            padding: 40,
          }}
        >
          <Space direction="vertical">
            <img
              src={iterativeLogo}
              style={{ width: 107, height: "auto", objectFit: "cover" }}
              alt="iterative-logo"
            />
            <Typography.H1 style={{ color: "white" }}>
              Demo Day Winter 2024 
            </Typography.H1>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

const WaitingView = () => {
  const calculateTimeLeft = () => {
    const now = moment();
    const minutesDiff = Math.abs(now.diff(DEMO_DAY_START_TIME, "minutes"));
    const hoursDiff = minutesDiff / 60;
    const daysDiff = hoursDiff / 24;

    return { minutesDiff, hoursDiff, daysDiff };
  };

  const checkHasStarted = () => moment().isAfter(DEMO_DAY_START_TIME);
  const timer = useCallback(
    () =>
      setTimeout(() => {
        ReactDOM.unstable_batchedUpdates(() => {
          setTimeDiff(calculateTimeLeft());
          setHasStarted(checkHasStarted());
        });
      }, 5000),
    []
  );

  const [hasStarted, setHasStarted] = useState(checkHasStarted());
  const [timeDiff, setTimeDiff] = useState(calculateTimeLeft());

  useEffect(() => {
    timer();
  }, [timeDiff]);

  // const { minutesDiff, hoursDiff, daysDiff } = timeDiff;
  return (
    <Row justify="space-between">
      <Col flex="450px">
        <Space direction="vertical" size={24} style={{ marginBottom: 24 }}>
          <Typography.H2>
            {hasStarted ? (
              <>
                Demo day has{" "}
                <span style={{ textDecoration: "underline" }}>started</span>,
                please refresh.
              </>
            ) : (
              "Demo Day hasn’t started."
            )}
          </Typography.H2>
          <span>Demo Day will start on Wednesday, 20 September at 10:00AM (SGT).</span>
          <span>
            To find out more about the event and the companies, please see{" "}
            <a
              href="https://www.iterative.vc/post/meet-the-summer-2023-batch"
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                analytics.track("Home - Clicked Find Out More Link")
              }
            >
              Iterative Demo Day Winter 2024 
            </a>
            . If you have questions, please email{" "}
            <a href="mailto:sylvia@iterative.vc">sylvia@iterative.vc</a>.
          </span>
        </Space>
      </Col>
    </Row>
  );
};

const HomePage = () => {
  const { allAirtable } = useStaticQuery(
    graphql`
      query {
        allAirtable {
          nodes {
            fields {
              stim
            }
          }
        }
      }
    `
  );

  const stims = getStimsFromData(allAirtable);
  const login = loginWithStims(stims);
  const checkLogin = checkIsLoggedInWithStims(stims);

  const [authenticated, setAuthenticated] = useState(false);
  const [openIntroModal, setOpenIntroModal] = useState(false);

  useEffect(() => {
    analytics.page("Home");
    const email = window && window.localStorage.getItem(UU_KEY);
    if (email) {
      analytics.identify(email);
    }
    setAuthenticated(checkLogin());
  }, [checkLogin]);

  const onLoginSuccess = () => {
    message.success("Welcome to Demo Day  Winter 2024");
    setAuthenticated(true);
  };

  const demoHasStarted = moment().isAfter(DEMO_DAY_START_TIME);
  let randomSeed = "";
  if (typeof window !== "undefined") {
    randomSeed = window.localStorage.getItem(PP_KEY);
  }
  return (
    <Layout>
      {authenticated ? (
        <>
          {/* Sticky banner - Remove */}
          {STICKY_BANNER_CONTENT && (
            <Row
              justify="center"
              align="middle"
              style={{
                position: "fixed",
                zIndex: 1,
                height: STICKY_BANNER_HEIGHT,
                backgroundColor: "#000",
                color: "#fff",
                width: "100%",
                boxShadow:
                  "0 15px 35px 0 rgb(60 66 87 / 8%), 0 5px 15px 0 rgb(0 0 0 / 12%)",
                display: "none",
              }}
            >
              <span>{STICKY_BANNER_CONTENT}</span>
            </Row>
          )}

          {/* Hero section */}
          <div
            style={{
              minHeight: 355 + STICKY_BANNER_HEIGHT,
              width: "100%",
              backgroundColor: "black",
              paddingTop: STICKY_BANNER_HEIGHT,
            }}
          >
            <section
              style={{
                maxWidth: 1010,
                margin: "auto",
                height: 55,
                padding: "14px 40px",
              }}
            >
              <img
                src={iterativeLogo}
                style={{ width: 107, height: "auto", objectFit: "cover" }}
                alt="iterative-logo"
              />
            </section>

            <section style={{ maxWidth: 1010, margin: "auto", padding: 40 }}>
              <Row
                gutter={[30, 30]}
                justify="space-between"
                align="top"
                style={{ height: "100%" }}
              >
                <Col xs={24} xl={12}>
                  <Typography.H1 style={{ color: "white" }}>
                    Demo Day Winter 2024 
                  </Typography.H1>
                </Col>
                {demoHasStarted && (
                  <Col xs={24} xl={12}>
                    <Typography.Body2
                      style={{ color: "white", marginBottom: 25 }}
                    >
                      The  Winter 2024 batch consists of 23 companies. Iterative
                      selected these companies from over 1,000 applications and
                      invested in them at the beginning of the program.
                    </Typography.Body2>
                    <Typography.Body2 style={{ color: "white" }}>
                      To learn more about Iterative and the program.
                    </Typography.Body2>

                    {
                      <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => {
                          analytics.track("Home - Clicked Watch Introduction");
                          setOpenIntroModal(true);
                        }}
                      >
                        <span
                          style={{
                            textDecoration: "underline",
                            fontWeight: "bold",
                            fontSize: 16,
                          }}
                        >
                          Watch Introduction
                        </span>
                      </Button>
                    }
                  </Col>
                )}
              </Row>
            </section>
          </div>

          <div
            style={{
              maxWidth: 1010,
              margin: "auto",
              marginBottom: 160,
              padding: 40,
              marginTop: 60,
              height: "100%",
              minHeight: "calc(100vh - 565px)",
            }}
          >
            {demoHasStarted ? (
              <>
                {/* How it works section */}
                <section style={{ marginBottom: 50 }}>
                  <Typography.H2 style={{ marginBottom: 24 }}>
                    How it Works
                  </Typography.H2>
                  <Row gutter={[30, 30]}>
                    <Col xs={24} xl={12}>
                      <Space direction="vertical">
                        <span style={{ fontWeight: "bold" }}>
                          Learning About Companies
                        </span>
                        <span>
                          Each company has recorded a 3 minute recording of
                          their pitch that explains the problem they solve,
                          their traction and their ask.
                        </span>
                      </Space>
                    </Col>
                    <Col xs={24} xl={12}>
                      <Space direction="vertical">
                        <span style={{ fontWeight: "bold" }}>
                          Connecting with Companies
                        </span>
                        <span>There are 2 ways you can contact companies.</span>
                        <ol>
                          <li>
                            <span style={{ fontWeight: "bold" }}>
                              Connect about Investing
                            </span>{" "}
                            – Sends the founder an email indicating you're
                            interested in talking to them about a potential
                            investment.
                          </li>
                          <li>
                            <span style={{ fontWeight: "bold" }}>
                              Show Love
                            </span>{" "}
                            – Sends the founder an email indicating you simply
                            like what they are doing.
                          </li>
                        </ol>
                      </Space>
                    </Col>
                  </Row>
                </section>

                {/* List of companies */}
                <section>
                  <Typography.H2 style={{ marginBottom: 50 }}>
                    Companies
                  </Typography.H2>
                  <Space
                    direction="vertical"
                    size={50}
                    style={{ width: "100%" }}
                  >
                    {ss.shuffle(COMPANIES, randomSeed).map((company, index) => {
                      const isLastCompany = index === COMPANIES.length - 1;
                      return (
                        <div key={company.name}>
                          <CompanyCard
                            name={company.name}
                            oneLiner={company.oneLiner}
                            description={company.description}
                            pitchVideoUrl={company.pitchVideoUrl}
                            qnaVideoUrl={company.qnaVideoUrl}
                            members={company.members}
                            useStaticImage={company.useStaticImage}
                            staticImageUrl={company.staticImageUrl}
                          />
                          {!isLastCompany && <Divider />}
                        </div>
                      );
                    })}
                  </Space>
                </section>
              </>
            ) : (
              <WaitingView />
            )}
          </div>

          {/* Footer */}
          <div
            style={{ minHeight: 150, backgroundColor: "black", width: "100%" }}
          >
            <section style={{ maxWidth: 1010, margin: "auto", padding: 40 }}>
              <img
                src={iterativeLogo}
                style={{
                  width: 107,
                  height: "auto",
                  objectFit: "cover",
                  marginBottom: 50,
                }}
                alt="iterative-logo"
              />
              <p style={{ color: "#999" }}>
                Thanks to{" "}
                <a
                  href="https://www.linkedin.com/in/marx-low/"
                  style={{ color: "#999", textDecoration: "underline" }}
                >
                  Marx Low
                </a>{" "}
                and{" "}
                <a
                  href="https://www.linkedin.com/in/lerk/"
                  style={{ color: "#999", textDecoration: "underline" }}
                >
                  En Lerk Law
                </a>{" "}
                for helping with this website.
              </p>
            </section>
          </div>
        </>
      ) : (
        <LoginPage onLoginSuccess={onLoginSuccess} login={login} />
      )}
      {openIntroModal && (
        <Modal
          centered
          closable={false}
          visible={openIntroModal}
          onCancel={() => {
            analytics.track("Home - Closed Introduction Video Modal");
            setOpenIntroModal(false);
          }}
          footer={null}
          bodyStyle={{
            height: 500,
            padding: 0,
            position: "relative",
            paddingBottom: "56.25%",
            backgroundColor: "transparent",
          }}
          width={1010}
        >
          <iframe
            allowFullScreen
            title="Iterative Demo Day Introduction"
            src={INTRODUCTION_VIDEO_VIMEO_LINK}
            width="100%"
            height="100%"
            style={{ position: "absolute" }}
            frameBorder="0"
            allow="autoplay; fullscreen"
          />
        </Modal>
      )}
    </Layout>
  );
};

export default HomePage;
